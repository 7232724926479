import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import { CSVLink, CSVDownload } from 'react-csv';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import PdfGenerator from '../../components/PdfGenerator';
import moment from 'moment';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const ItemPage = () => {
  const [itemData, setItemData] = useState();
  const [showDownload, setShowDownload] = useState(false);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const [pdfData, setPdfData] = useState({
    tableTitles: [
      'POC No',
      'POC Date',
      'POC Status',
      'Cust. Name',
      'Cust Srt',
      'Prc Type',
      'Col',
      'POC Qty',
      'Jbcrd Mt',
      'Diff',
    ],
    tableContents: [],
    from: '',
    to: '',
  });
  const state = useContext(GlobalStateData);
  useEffect(() => {
    authCheck(state.authData);
    getCustomers();
    getSorts('');
  }, []);

  useEffect(() => {
    if (tableData.length) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [tableData]);

  const postApi = () => {
    const postData = {
      data: {
        customer,
        customerSort,
        from,
        to,
      },
    };
    let validate = requireCheck(postData);
    if (from && to) {
      var deliveryDateValid = dateGreaterOrEqual(to, from, 'to', 'Invalid To Date');
    } else {
      var deliveryDateValid = true;
    }

    if (!validate || !deliveryDateValid) {
      return false;
    }
    let cust = customer.toString();
    let sort = customerSort.toString();
    showLoader();
    axios
      .get(
        baseUri() +
          'reports/poc-against-jobcard-issue?users=' +
          cust +
          '&customersSort=' +
          sort +
          '&from=' +
          from +
          '&to=' +
          to,
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        const tablecontent = [];
        const pdfTotal = {
          POC_Qty: 0,
          JOB_Card_Mts: 0,
        };
        response.data.data.forEach(
          (t: {
            POC_Num: any;
            POC_Date: any;
            POC_Status: any;
            Customer_Name: any;
            Cusomter_Sort: any;
            Process_Type: any;
            Colour: any;
            POC_Qty: any;
            JOB_Card_Mts: any;
            Diff: any;
          }) => {
            tablecontent.push([
              t.POC_Num,
              t.POC_Date,
              t.POC_Status,
              t.Customer_Name,
              t.Cusomter_Sort,
              t.Process_Type,
              t.Colour,
              (+t.POC_Qty).toLocaleString('en-IN', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              (+t.JOB_Card_Mts).toLocaleString('en-IN', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              (+t.Diff).toLocaleString('en-IN', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            ]);
            pdfTotal.JOB_Card_Mts += +t.JOB_Card_Mts;
            pdfTotal.POC_Qty += +t.POC_Qty;
          },
        );
        tablecontent.push([
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          pdfTotal.JOB_Card_Mts.toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          pdfTotal.POC_Qty.toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          ' ',
        ]);
        setItemData(response.data.data);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data);
        }
        setPdfData((prev) => {
          return {
            ...prev,
            from: moment(from).format('DD-MM-YY'),
            to: moment(to).format('DD-MM-YY'),
            tableContents: tablecontent,
          };
        });
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };
  const [customer, setCustomer] = useState([]);
  const [defaultCustomer, setDefaultCustomer] = useState([{ label: 'All', value: '' }]);
  const [userOptions, setUserOptions] = useState([]);
  const [customerSort, setCustomerSort] = useState([]);
  const [defaultCustomerSort, setDefaultCustomerSort] = useState([{ label: 'All', value: '' }]);
  const [sortOptions, setSortOptions] = useState([]);
  const [from, setFrom] = useState(dateFormat(new Date().toISOString()));
  const [to, setTo] = useState(dateFormat(new Date().toISOString()));

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'from':
        setFrom(event.target.value);
        if (event.target.value) {
          document.getElementsByClassName('to')[0].classList.add('require');
        } else {
          document.getElementsByClassName('to')[0].classList.remove('require');
        }
        break;
      case 'to':
        setTo(event.target.value);
        break;
    }
  };

  const handleCustomerChange = (event) => {
    let selected = [];
    let fliterStr = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      if (item.value) {
        selected.push(item.value);
        fliterStr.push(`filters[user][id][$in][${index}]=${item.value}`);
      }
    });
    setCustomer(selected);
    setDefaultCustomer(event);
    pullDownReset('customer');
    setCustomerSort([]);
    setDefaultCustomerSort([{ label: 'All', value: '' }]);
    setSortOptions([]);
    getSorts(fliterStr.join('&'));
  };

  const handleCustomerSortChange = (event) => {
    let selected = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      selected.push(item.value);
    });
    setCustomerSort(selected);
    setDefaultCustomerSort(event);
    pullDownReset('customerSort');
  };

  const clearFormData = () => {
    getSorts('');
    setFrom('');
    setTo('');
    setCustomer([]);
    setDefaultCustomer([{ label: 'All', value: '' }]);
    setCustomerSort([]);
    setDefaultCustomerSort([{ label: 'All', value: '' }]);
    resetRequired();
    document.getElementsByClassName('to')[0].classList.remove('require');
  };

  const getCustomers = () => {
    axios
      .get(baseUri() + 'users?filters[userType][$eq]=4', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.forEach(function (item, index) {
          options.push({ label: item.userCode + ' - ' + item.name, value: item.id });
        });
        setUserOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getSorts = (query) => {
    setCustomerSort([]);
    showLoader();
    axios
      .get(baseUri() + `user-sorts?${query}`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.sortName, value: item.id });
        });
        setSortOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const columns = [
    {
      name: 'POC_Num',
      selector: (row) => row.POC_Num,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'POC_Date',
      selector: (row) => row.POC_Date,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'POC_Status',
      selector: (row) => row.POC_Status,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Customer_Name',
      selector: (row) => row.Customer_Name,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Cusomter_Sort',
      selector: (row) => row.Cusomter_Sort,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'POC_Qty',
      selector: (row) => row.POC_Qty,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Process_Type',
      selector: (row) => row.Process_Type,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Colour',
      selector: (row) => row.Colour,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'POC Qty',
      selector: (row) => row.POC_Qty,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Job Card Mts',
      selector: (row) => row.JOB_Card_Mts,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Diff',
      selector: (row) => row.Diff,
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const filterColumns = [
    'POC_Num',
    'POC_Date',
    'POC_Status',
    'Customer_Name',
    'Cusomter_Sort',
    'POC_Qty',
    'Process_Type',
    'Colour',
    'JOB_Card_Mts',
    'Diff',
  ];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  return (
    <>
      <SEO title="POC against Jobcard Issue" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> POC Against JobCard Issue</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Customer <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleCustomerChange(e)}
                    options={userOptions}
                    id="customer"
                    name="customer"
                    placeholder="Select Customer *"
                    value={defaultCustomer}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Customer Sort</label>
                  <SelectField
                    fullWidth
                    onChange={(e: any) => handleCustomerSortChange(e)}
                    options={sortOptions}
                    id="customerSort"
                    name="customerSort"
                    placeholder="Select Customer Sort"
                    value={defaultCustomerSort}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    From Date <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="from"
                      placeholder="From Date"
                      onChange={(e) => handleChange(e)}
                      value={from}
                      className="from require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    To Date <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="to"
                      placeholder="To Date"
                      onChange={(e) => handleChange(e)}
                      value={to}
                      className="to require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button status="Success" type="button" className="btnrad" shape="" halfWidth onClick={postApi}>
                    Get Report
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>POC Against JobCard Issue</header>
            <CardBody>
              {showDownload && (
                <p>
                  <PdfGenerator documentTitle="poc-against-jobcard-issue" data={pdfData} />
                  {' '}
                  <CSVLink data={tableData}>Download Excel</CSVLink>
                </p>
              )}
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ItemPage;
